import { isCurrentView } from "../../../shared/utils/view-util";

$(() => {
  if (!isCurrentView(".sbsa-portal.online_presence")) {
    return;
  }

  let addButtonDisabled = true;

  const form = "online_presence_form";

  const modal = $("#add_online_presence_modal");

  const addButton = $("#add-online-presence");
  const saveButton = $("#save-online-presence");
  const backButton = $("#back_button");

  const storeType = $(`#${form}_store_type`);

  const onlinePresenceContainer = $(`.${form}_online_presence_checked`);
  const radiosOnlinePresence = $(
    `input[type=radio][name="${form}[online_presence_checked]"]`
  );

  const website = $(`#${form}_website`);
  const facebook = $(`#${form}_facebook`);
  const instagram = $(`#${form}_instagram`);
  const linkedin = $(`#${form}_linkedin`);
  const tikTok = $(`#${form}_tik_tok`);
  const twitter = $(`#${form}_twitter`);
  const platform = $(`#${form}_platform`);
  const platformUrl = $(`#${form}_platform_url`);

  const platformUrlContainer = $("#platform_url_container");
  const platformsContainer = $("#platforms_container");

  const platformMappings = {
    website: {
      label: "Website",
      regex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
      example: "https://www.mybusiness.co.za",
    },
    facebook: {
      label: "Facebook",
      regex:
        /^(?:https:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/([A-z0-9_\-\.]{5,})\/?$/g,
      example: "https://www.facebook.com/mybusiness",
    },
    instagram: {
      label: "Instagram",
      regex:
        /^(?:https:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/@?([A-z0-9_\.]{1,30})\/?$/g,
      example: "https://www.instagram.com/@mybusiness",
    },
    linkedin: {
      label: "LinkedIn",
      regex: /^(?:https:\/\/)?(?:[\w]+\.)?linkedin\.com\/in\/([\w\-\_]+)\/?$/g,
      example: "https://www.linkedin.com/in/mybusiness",
    },
    tik_tok: {
      label: "TikTok",
      regex:
        /^(?:https:\/\/)?(?:www\.)?tiktok\.com\/@?([A-z0-9_\-\.]{1,24})\/?$/g,
      example: "https://www.tiktok.com/@mybusiness",
    },
    twitter: {
      label: "X",
      regex:
        /^(?:https:\/\/)?(?:www\.)?(?:twitter|x)\.com\/@?([A-z0-9_]{4,15})\/?$/g,
      example: "https://www.x.com/@mybusiness",
    },
  };

  const platformOptions = [
    {
      key: "website",
      name: "Website",
    },
    {
      key: "facebook",
      name: "Facebook",
    },
    {
      key: "instagram",
      name: "Instagram",
    },
    {
      key: "linkedin",
      name: "LinkedIn",
    },
    {
      key: "tik_tok",
      name: "TikTok",
    },
    {
      key: "twitter",
      name: "X",
    },
  ];

  // Initialize elements
  addButton.prop("disabled", true);

  setPlatform("website", website.val());
  setPlatform("facebook", facebook.val());
  setPlatform("instagram", instagram.val());
  setPlatform("linkedin", linkedin.val());
  setPlatform("tik_tok", tikTok.val());
  setPlatform("twitter", twitter.val());

  setPlatformOptions();

  if (storeType.val() === "brick_and_mortar") {
    onlinePresenceContainer.show();

    if (
      $(`#${form}_online_presence_checked_true:checked`) &&
      $(`#${form}_online_presence_checked_true:checked`).val()
    ) {
      platformsContainer.show();
      saveButton.show();
      backButton.show();
    } else if (
      $(`#${form}_online_presence_checked_false:checked`) &&
      $(`#${form}_online_presence_checked_false:checked`).val() !== undefined
    ) {
      platformsContainer.hide();
      saveButton.show();
      saveButton.prop("disabled", false);
      backButton.hide();
    } else {
      platformsContainer.hide();
      saveButton.hide();
      backButton.hide();
    }
  } else {
    onlinePresenceContainer.hide();

    if (onlinePresenceAdded()) {
      saveButton.prop("disabled", false);
      saveButton.show();
      backButton.show();
    } else {
      saveButton.hide();
      backButton.hide();
    }
  }

  // Active listeners
  radiosOnlinePresence.on("change", () => {
    if (
      $(`#${form}_online_presence_checked_false:checked`) &&
      $(`#${form}_online_presence_checked_false:checked`).val() !== undefined
    ) {
      saveButton.prop("disabled", false);
      platformsContainer.hide();

      saveButton.show();
      backButton.hide();
    } else if (
      $(`#${form}_online_presence_checked_true:checked`) &&
      $(`#${form}_online_presence_checked_true:checked`).val()
    ) {
      if (onlinePresenceAdded()) {
        saveButton.prop("disabled", false);
        saveButton.show();
        backButton.show();
      } else {
        saveButton.prop("disabled", true);
        saveButton.hide();
        backButton.hide();
      }

      platformsContainer.show();
    }
  });

  $(platform).on("change", (_event, reset) => {
    const platformValue = platform.val();

    if (!reset) {
      toggleInputErrorState(
        "online_presence_form_platform",
        !platformValue ? "Please select your online platform." : null
      );
    }

    if (platformValue) {
      updateInputLabelDisplay(
        `label[for='${form}_platform_url']`,
        `#${form}_platform_url`,
        `${platformMappings[platformValue]["label"]} URL`
      );

      platformUrlContainer.show();
    } else {
      updateInputLabelDisplay(
        `label[for='${form}_platform_url']`,
        `#${form}_platform_url`,
        `Online platform URL`
      );

      toggleInputErrorState("online_presence_form_platform_url", null);

      platformUrl.val("").trigger("change");

      platformUrlContainer.hide();
    }
  });

  $(platformUrl).on("input change", () => {
    if (addButtonDisabled && platformUrl.val()) {
      addButton.prop("disabled", false);
      addButtonDisabled = false;
    } else if (!addButtonDisabled && !platformUrl.val()) {
      addButton.prop("disabled", true);
      addButtonDisabled = true;
    }
  });

  $(platformUrl).on("keydown", (event) => {
    if (event.key === "Enter") {
      if (modal.is(":visible")) {
        // Cancel the default action, if needed
        event.preventDefault();
        event.stopPropagation();
        // Trigger the add button element with a click
        addButton.click();
        return false;
      }
    }
  });

  addButton.on("click", (_event) => {
    const platformValue = platform.val();
    const urlValue = platformUrl.val();
    const sanitizedUrl = urlValue.split("?")[0];

    const error = urlError(platformValue, sanitizedUrl);

    toggleInputErrorState(
      "online_presence_form_platform",
      !platformValue ? "Please select your online platform." : null
    );

    toggleInputErrorState(
      "online_presence_form_platform_url",
      platformValue
        ? !sanitizedUrl
          ? "Please enter your online platform URL."
          : error
          ? error
          : null
        : null
    );

    if (platformValue && sanitizedUrl && !error) {
      $(`#${form}_${platformValue}`).val(sanitizedUrl);
      $(`#${platformValue}_container`).show();

      updateInputLabelDisplay(
        `label[for='${form}_${platformValue}']`,
        `#${form}_${platformValue}`,
        `${platformMappings[platformValue]["label"]} URL`
      );

      setPlatform(platformValue, sanitizedUrl);

      setPlatformOptions();

      $("#error-container").hide();

      saveButton.show();
      backButton.show();

      // jQuery not available with webpacker
      // work around until fully reworked and we can address the issue
      $("#close-modal").click();
    }
  });

  $(".close-button").on("click", () => {
    platform.val("").trigger("change", true);
  });

  $("#remove_website_icon").on("click", () => {
    setPlatform("website");
    setPlatformOptions();
  });

  $("#remove_facebook_icon").on("click", () => {
    setPlatform("facebook");
    setPlatformOptions();
  });

  $("#remove_instagram_icon").on("click", () => {
    setPlatform("instagram");
    setPlatformOptions();
  });

  $("#remove_linkedin_icon").on("click", () => {
    setPlatform("linkedin");
    setPlatformOptions();
  });

  $("#remove_tik_tok_icon").on("click", () => {
    setPlatform("tik_tok");
    setPlatformOptions();
  });

  $("#remove_twitter_icon").on("click", () => {
    setPlatform("twitter");
    setPlatformOptions();
  });

  function urlError(platform, url) {
    if (
      platformMappings[platform] &&
      platformMappings[platform]["regex"] &&
      !url.match(platformMappings[platform]["regex"])
    ) {
      return `Please enter a valid ${platformMappings[platform]["label"]} URL, e.g. ${platformMappings[platform]["example"]}`;
    }

    return null;
  }

  function setPlatformOptions() {
    platform.find("option:not(:first)").remove();
    platform.val("").trigger("change", true);

    const availablePlatformOptions = platformOptions.filter(
      (option) =>
        $(`#${form}_${option.key}`) && !$(`#${form}_${option.key}`).val()
    );

    availablePlatformOptions.forEach((option) =>
      appendToSelect(platform, option.key, option.name)
    );

    if (!availablePlatformOptions.length) {
      $(`#add_online_platform_container`).hide();
    } else if (availablePlatformOptions.length >= 6) {
      $("#add_main_platform_button").show();
      $("#add_additional_platforms_button").hide();
      $("#online_platforms_header").hide();

      saveButton.prop("disabled", true);
    } else {
      $(`#add_online_platform_container`).show();
      $("#add_main_platform_button").hide();
      $("#add_additional_platforms_button").show();
      $("#online_platforms_header").show();

      if (availablePlatformOptions.length === 5) {
        $(".remove-icon").hide();
      } else {
        $(".remove-icon").show();
      }

      saveButton.prop("disabled", false);
    }
  }

  function toggleInputErrorState(selector, message) {
    const elementBlock = $(`.${selector}`);

    if (message) {
      if (
        elementBlock &&
        elementBlock[0] &&
        elementBlock[0].children &&
        elementBlock[0].children[0]
      ) {
        if (elementBlock[0].children[0]) {
          elementBlock[0].children[0].classList.add("is-invalid");
        }

        if (!elementBlock[0].children[2]) {
          elementBlock.append(`<div class="invalid-feedback">${message}</div>`);
        } else {
          elementBlock[0].children[2].innerHTML = message;
        }
      }
    } else {
      if (
        elementBlock &&
        elementBlock[0] &&
        elementBlock[0].children &&
        elementBlock[0].children[0]
      ) {
        if (elementBlock[0].children[0]) {
          elementBlock[0].children[0].classList.remove("is-invalid");
        }

        if (elementBlock[0].children[2]) {
          elementBlock[0].children[2].remove();
        }
      }
    }
  }

  function setPlatform(platform, value = "") {
    if (value) {
      $(`#${platform}_container`).show();
    } else {
      $(`#${platform}_container`).hide();
      toggleInputErrorState(`online_presence_form_${platform}`, null);
    }

    $(`#${form}_${platform}`).val(value);

    updateInputLabelDisplay(
      `label[for='${form}_${platform}']`,
      `#${form}_${platform}`,
      `${platformMappings[platform]["label"]} URL`
    );
  }

  function onlinePresenceAdded() {
    return !!(
      website.val() ||
      facebook.val() ||
      instagram.val() ||
      linkedin.val() ||
      tikTok.val() ||
      twitter.val()
    );
  }
});
