import { isCurrentView } from '../../../shared/utils/view-util';

$(() => {
  if (!isCurrentView('.sbsa-portal.business_address.index')) { return; }

  const phoneNumber = document.getElementById("sbsa_business_address_form_store_info_phone");

  phoneNumber.addEventListener("input", (e) => {
    // Only allow digits
    phoneNumber.value = phoneNumber.value.replace(/\+D/g,'');

    if (!isValidPhoneNumber(phoneNumber.value)) {
      phoneNumber.classList.add('is-invalid');
    } else {
      phoneNumber.classList.remove('is-invalid');
    }
  });

  function isValidPhoneNumber(phoneNumberString) {
    // The below regex will match valid local and international mobile numbers
    const regEx = /^(\+27|27|0)[6-8][0-9]{8}$|^\+[1-9][0-9]{6,14}$/;
    return phoneNumberString.match(regEx) != null;
  }
});
